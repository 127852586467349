<template>
  <div class="card">
    <div class="card-header">
      <h4>Agency Fee Table</h4>
    </div>
    <div class="card-body">
      <v-data-table
        :headers="headers"
        :items="data"
        item-key="id"
        :search="search"
        hide-default-footer
        :page.sync="page"
        :items-per-page="itemsPerPage"
        class="elevation-1"
        @page-count="pageCount = $event"
      >
        <template v-slot:item="row">
          <tr>
            <td>Rp. {{ formatPrice(row.item.fee) }}</td>
            <td>{{ row.item.ladies_type }}</td>
            <td>
              <button
                @click="deleteAgencyFee(row.item.id)"
                class="badge badge-danger"
              >
                Delete
              </button>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <div class="card-footer text-right">
      <nav class="d-inline-block">
        <ul class="pagination mb-0">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert";
import axios from "axios";

export default {
  data() {
    return {
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  props: ["data"],
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    deleteAgencyFee(id) {
      swal({
        title: "Are you sure?",
        text:
          "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .delete(this.$store.state.api + "agency_ladies_fee/" + id, {
              headers: { Authorization: this.$store.state.token },
            })
            .then(
              () =>
                (this.data = this.data.filter((v) => {
                  return v.id != id;
                }))
            )
            .catch((err) => {
              console.log(err);
              swal("Error!", err.message, "error");
            });
          swal("Poof! Your Ladies Agency file has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Your Ladies Agency file is safe!");
        }
      });
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Fee",
          value: "fee",
        },
        {
          text: "Ladies Type",
          value: "ladies_type_id",
        },
        { text: "Action" },
      ];
    },
  },
};
</script>
